import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import { db } from "../firestore";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Alert, CircularProgress, Snackbar } from "@mui/material";

function Home() {
  const [promoVisible, setPromoVisible] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function saveForm() {
    if (name === "" || name === undefined) {
      setErrorMessage("Введите Ваше имя!");
      setErrorSnackbarOpen(true);
      return;
    }

    if (phone.length !== 11) {
      setErrorMessage("Введите корректный номер телефона!");
      setErrorSnackbarOpen(true);
      return;
    }

    const q = query(
      collection(db, "promo_responses_2"),
      where("phone", "==", phone)
    );

    setLoading(true);
    const querySnapshot = await getDocs(q);
    setLoading(false);

    if (querySnapshot.docs.length > 0) {
      setErrorMessage("Данный номер уже зарегистрирован!");
      setErrorSnackbarOpen(true);
      setPromoVisible(true);
      return;
    }

    setLoading(true);
    await addDoc(collection(db, "promo_responses_2"), {
      name: name,
      phone: phone.toString(),
      promo: Cookies.get("roistat_visit").toString(),
      date: new Date(),
    }).then((r) => console.log(r));
    setLoading(false);
    setPromoVisible(true);
  }

  return (
    <div>
      <div
        style={{
          fontSize: "20px",
          fontWeight: "600",
          marginTop: "200px",
          marginBottom: "20px",
        }}
      >
        Пожалуйста, заполните форму для участия в розыгрыше!
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          padding: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextField
          className="text-field"
          onChange={(e) => setName(e.target.value)}
          style={{ width: "250px", margin: "10px" }}
          id="promo-name"
          placeholder={"Ваше имя"}
          variant="outlined"
        />
        <div style={{ margin: "10px" }}>
          <PhoneInput
            inputStyle={{ height: "54px", width: "250px" }}
            country={"kz"}
            id="promo-phone"
            onlyCountries={["kz"]}
            placeholder={"Номер телефона"}
            specialLabel={"Номер телефона"}
            value={phone}
            onChange={(phone) => setPhone(phone)}
          />
        </div>
        <Button
          disabled={loading}
          id="promo-button-submit"
          style={{ width: "250px", margin: "10px", height: "54px" }}
          size="large"
          onClick={() => {
            saveForm();
          }}
          variant="contained"
        >
          {loading ? (
            <CircularProgress
              size={24}
              thickness={6}
              style={{ color: "#FFF" }}
            />
          ) : (
            "Отправить"
          )}
        </Button>
      </div>
      {promoVisible && (
        <div>
          <div>
            <div
              style={{
                marginTop: "40px",
              }}
            >
              Номер Вашего билета:
            </div>
            <div
              style={{
                marginTop: "10px",
                fontSize: "40px",
                fontWeight: "800",
                color: "green",
              }}
            >
              {Cookies.get("roistat_visit")}
            </div>
          </div>
        </div>
      )}

      <Snackbar
        open={errorSnackbarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={() => setErrorSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setErrorSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default Home;
