// noinspection SpellCheckingInspection

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDorMUPPyVvYDLhHVcF42-DYpd0S8Q84aw",
  authDomain: "roistatpromo.firebaseapp.com",
  projectId: "roistatpromo",
  storageBucket: "roistatpromo.appspot.com",
  messagingSenderId: "63068560010",
  appId: "1:63068560010:web:bc7daedf0329d913a16b73",
};
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
